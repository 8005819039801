import React, { useState } from 'react';
import { FaEnvelope, FaQuestionCircle, FaBug, FaUserCog, FaHandshake } from 'react-icons/fa';
import './Styles/Contact.css';
import { sendContactMessage } from '../services/api';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        category: 'general',
        subject: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const categories = [
        { id: 'general', label: 'General Inquiry', icon: FaQuestionCircle },
        { id: 'technical', label: 'Technical Support', icon: FaBug },
        { id: 'account', label: 'Account Support', icon: FaUserCog },
        { id: 'business', label: 'Business Inquiry', icon: FaHandshake }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await sendContactMessage(formData);
            setFormData({ name: '', email: '', subject: '', message: '' });
            toast(<toast type="success" message="Message sent successfully! We'll respond soon." />);
        } catch (error) {
            console.error('Error:', error);
            toast(<toast type="error" message="Failed to send message. Please try again." />);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="contact-container">
            <Helmet>
                <title>Contact Cognora | Get in Touch</title>
                <meta name="description" content="Contact Cognora for questions about our AI-powered educational platform, technical support, or business inquiries." />
                <link rel="canonical" href="https://cognora.ca/contact" />
                <meta property="og:title" content="Contact Cognora | Get in Touch" />
            </Helmet>
            <div className="contact-hero">
                <h1>Contact Us</h1>
                <p>We're here to help! Select a category below or send us a general message.</p>
            </div>

            <div className="contact-categories">
                {categories.map(({ id, label, icon: Icon }) => (
                    <button
                        key={id}
                        className={`category-btn ${formData.category === id ? 'active' : ''}`}
                        onClick={() => setFormData({ ...formData, category: id })}
                    >
                        <Icon />
                        <span>{label}</span>
                    </button>
                ))}
            </div>

            <div className="contact-content">
                <div className="contact-info">
                    <div className="info-card">
                        <FaEnvelope />
                        <h3>Email Us Directly</h3>
                        <p>support@cognora.ca</p>
                        <small>We typically respond within 24 hours</small>
                    </div>
                </div>

                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                            required
                            style={{ resize: 'none' }}
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-btn" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Contact; 