import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Styles/Blog.css';
import { CodeBlock } from './Partials/CodeBlock';
import { blogPosts } from './Data/BlogPosts';

const BlogPost = ({ theme }) => {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === parseInt(id)) || blogPosts.find(post => post.slug === id);

  if (!post) {
    return (
      <div className="blog-post-not-found">
        <h1>Blog Post Not Found</h1>
        <Link to="/blog" className="return-button">
          ← Back to Blog
        </Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} | Cognora Blog`}</title>
        
        {/* Default meta tags */}
        <meta name="description" content={post.excerpt} />
        <meta name="keywords" content={post.keywords.join(", ")} />
        <link rel="canonical" href={`https://cognora.ca/blog/${post.slug}`} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:image" content={`https://cognora.ca${post.thumbnailUrl}`} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:url" content={`https://cognora.ca/blog/${post.slug}`} />
        <meta name="twitter:image" content={`https://cognora.ca${post.thumbnailUrl}`} />
        <meta property="og:image:alt" content={post.title} />
        <meta property="og:site_name" content="Cognora" />
        
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@cognoraai" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />

        
        {/* Article specific meta tags */}
        <meta property="article:published_time" content={post.date} />
        <meta property="article:modified_time" content={post.lastModified} />
        <meta property="article:author" content={post.author.name} />
        {post.tags.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "image": post.imageUrl,
            "datePublished": post.date,
            "dateModified": post.lastModified,
            "author": {
              "@type": "Person",
              "name": post.author.name,
              "description": post.author.bio
            },
            "publisher": {
              "@type": "Organization",
              "name": "Cognora",
              "logo": {
                "@type": "ImageObject",
                "url": "https://cognora.ca/logo.png"
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://cognora.ca/blog/${post.slug}`
            },
            "description": post.excerpt,
            "keywords": post.tags.join(", "),
            "articleBody": post.content
          })}
        </script>
      </Helmet>

      <div className="blog-post-container">
        <div className="blog-post-mini-container">
          <div className="blog-card-image full">
            <img src={post.imageUrl} alt={post.title} />
          </div>
          <article className="blog-post">
            <Link to="/blog" className="return-button">
              ← Back to Blog
            </Link>
            <header className="blog-post-header">
              <div className="blog-post-meta-top">
                <span className="blog-post-category">{post.category}</span>
                <time dateTime={post.date}>{post.displayDate}</time>
              </div>
              <h1>{post.title}</h1>
              <div className="blog-tags">
                {post.tags.map(tag => (
                  <span key={tag} className="tag">{tag}</span>
                ))}
              </div>
              {/* <div className="blog-post-author">
                <div className="author-info">
                  <span className="author-name">By {post.author.name}</span>
                  <p className="author-bio">{post.author.bio}</p>
                </div>
              </div> */}
            </header>

            <div className="blog-post-content">
              <ReactMarkdown
                components={{
                  code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || '');
                    const language = match ? match[1] : '';
                    
                    if (!inline && match) {
                      return (
                        <CodeBlock
                          language={language}
                          content={String(children).replace(/\n$/, '')}
                          theme={theme}
                        />
                      );
                    }
                    return <code className={className} {...props}>{children}</code>;
                  }
                }}
              >
                {post.content}
              </ReactMarkdown>
            </div>
            
            <div className="blog-post-cta">
                <Link to="https://studdybuddy.ca/" className="cta-button">
                  Meet StudyBuddy - Your New Learning Companion
                </Link>
              </div>

            <footer className="blog-post-footer">
              <div className="share-section">
                <h3>Share this article</h3>
                <div className="share-buttons">
                  <a 
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.title)}&url=${encodeURIComponent(window.location.href)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-button"
                  >
                    <i className="fab fa-twitter"></i>
                    Twitter
                  </a>
                  <a 
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(post.title)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-button"
                  >
                    <i className="fab fa-linkedin"></i>
                    LinkedIn
                  </a>
                  <a 
                    href={`mailto:?subject=${encodeURIComponent(post.title)}&body=${encodeURIComponent(window.location.href)}`}
                    className="share-button"
                  >
                    <i className="fas fa-envelope"></i>
                    Email
                  </a>
                </div>
              </div>

            </footer>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlogPost; 