import React from 'react';
import { Helmet } from 'react-helmet';
import '../Styles/About.css';
import { motion } from 'framer-motion';

const About = () => {
  const aboutStructuredData = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "mainEntity": {
      "@type": "Organization",
      "name": "Cognora",
      "description": "AI-powered educational technology company revolutionizing personalized learning",
      "foundingDate": "2024",
      "knowsAbout": [
        "Artificial Intelligence",
        "Educational Technology",
        "Personalized Learning",
        "AI Tutoring"
      ]
    }
  };

  return (
    <>
      <Helmet>
        <title>About Cognora | Pioneering AI-Powered Education</title>
        <meta name="og:title" content="About Cognora | AI-Powered Education" />
        <meta name="description" content="Discover how Cognora is revolutionizing education through AI-powered personalized learning experiences. Learn about our mission, values, and vision for the future of education." />
        <meta name="keywords" content="Cognora company, AI education company, educational technology innovation, AI tutoring platform, StudyBuddy creator" />
        <link rel="canonical" href="https://cognora.ca" />
        
        <script type="application/ld+json">
          {JSON.stringify(aboutStructuredData)}
        </script>
      </Helmet>

      <div className="about-container">
        <section className="hero-section">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="hero-content"
          >
            <h1>Revolutionizing Education Through AI</h1>
            <p className="hero-subtitle">
              Making personalized learning accessible to everyone, one student at a time.
            </p>
          </motion.div>
        </section>

        <section className="vision-section">
          <div className="vision-content">
            <h2>Our Vision</h2>
            <p className="vision-text">
              We envision a world where every learner has access to personalized education 
              that moves at their pace, adapts to their needs, and helps them reach their 
              full potential. Through AI-powered solutions, we're making this vision a reality.
            </p>
          </div>
        </section>

        <section className="values-section">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <h3>Truth & Transparency</h3>
              <p>We believe in honest communication and authentic relationships with our users.</p>
            </div>
            <div className="value-card">
              <h3>Educational Innovation</h3>
              <p>Pushing the boundaries of what's possible in personalized learning through AI.</p>
            </div>
            <div className="value-card">
              <h3>Individual Empowerment</h3>
              <p>Supporting each learner's unique journey and potential.</p>
            </div>
            <div className="value-card">
              <h3>Accessible Learning</h3>
              <p>Making quality education available to everyone, everywhere.</p>
            </div>
          </div>
        </section>

        <section className="mission-section">
          <div className="mission-content">
            <h2>Our Mission</h2>
            <p className="mission-text">
              To revolutionize education by creating AI-powered solutions that adapt to 
              each individual's learning style, pace, and preferences. We're starting with 
              StudyBuddy, our intelligent learning companion that provides personalized 
              tutoring and study assistance.
            </p>
          </div>
        </section>

        <section className="approach-section">
          <h2>Our Approach</h2>
          <div className="approach-grid">
            <div className="approach-card">
              <h3>Personalized Learning</h3>
              <p>Our AI adapts to your unique learning style and pace, ensuring you get 
              the most effective educational experience possible.</p>
            </div>
            <div className="approach-card">
              <h3>Interactive Study</h3>
              <p>Engage with our AI through various modes - from Q&A to detailed tutorials 
              and challenging debates.</p>
            </div>
            <div className="approach-card">
              <h3>Continuous Evolution</h3>
              <p>We're constantly improving our technology to better serve learners' needs 
              and adapt to educational challenges.</p>
            </div>
          </div>
        </section>

        <section className="future-section">
          <h2>The Future of Learning</h2>
          <p className="future-text">
            We're at the beginning of our journey to transform education. As we grow, 
            we're committed to:
          </p>
          <ul className="future-list">
            <li>Expanding our AI capabilities to cover more subjects and learning styles</li>
            <li>Building partnerships with educational institutions</li>
            <li>Developing new tools for collaborative learning</li>
            <li>Making education more accessible and effective for everyone</li>
          </ul>
        </section>

        <section className="join-section">
          <h2>Join Our Journey</h2>
          <p className="join-text">
            We're in the early stages of our mission to revolutionize education. 
            Whether you're a student, educator, or institution, we invite you to be 
            part of this transformation.
          </p>
          <div className="cta-buttons">
            <a href="/contact" className="cta-button primary">Get in Touch</a>
            <a href="/studybuddy" className="cta-button secondary">Try StudyBuddy</a>
          </div>
        </section>
      </div>
    </>
  );
};

export default About; 