import React, { useState } from 'react';
import { Copy01Icon, Tick01Icon } from 'hugeicons-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import '../Styles/CodeBlock.css';

// Add this custom theme object
const customTheme = {
    'code[class*="language-"]': {
      color: '#d6deeb',
      fontFamily: 'var(--font-mono)',
      direction: 'ltr',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      lineHeight: '1.5',
      MozTabSize: '4',
      OTabSize: '4',
      tabSize: '4',
      WebkitHyphens: 'none',
      MozHyphens: 'none',
      msHyphens: 'none',
      hyphens: 'none',
    },
    'pre[class*="language-"]': {
      color: '#d6deeb',
      background: '#011627',
      fontFamily: 'var(--font-mono)',
      direction: 'ltr',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      lineHeight: '1.5',
      MozTabSize: '4',
      OTabSize: '4',
      tabSize: '4',
      WebkitHyphens: 'none',
      MozHyphens: 'none',
      msHyphens: 'none',
      hyphens: 'none',
      padding: '1em',
      margin: '0.5em 0',
      overflow: 'auto',
      borderRadius: '0.3em',
    },
    'comment': { color: '#637777', fontStyle: 'italic' },
    'prolog': { color: '#637777' },
    'doctype': { color: '#637777' },
    'cdata': { color: '#637777' },
    'punctuation': { color: '#c792ea' },
    'property': { color: '#80cbc4' },
    'tag': { color: '#7fdbca' },
    'boolean': { color: '#ff5874' },
    'number': { color: '#f78c6c' },
    'constant': { color: '#82aaff' },
    'symbol': { color: '#82aaff' },
    'deleted': { color: '#ff5874' },
    'selector': { color: '#c792ea' },
    'attr-name': { color: '#7fdbca' },
    'string': { color: '#ecc48d' },
    'char': { color: '#ecc48d' },
    'builtin': { color: '#82aaff' },
    'inserted': { color: '#80cbc4' },
    'operator': { color: '#c792ea' },
    'entity': { color: '#f78c6c' },
    'url': { color: '#66d9ef' },
    'variable': { color: '#addb67' },
    'atrule': { color: '#82aaff' },
    'attr-value': { color: '#c3e88d' },
    'function': { color: '#82aaff' },
    'class-name': { color: '#f78c6c' },
    'keyword': { color: '#c792ea' },
    'regex': { color: '#80cbc4' },
    'important': { color: '#c792ea', fontWeight: 'bold' },
  };
  

// Update the CodeBlock component to use the custom theme
export const CodeBlock = ({ language, content, theme }) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(content);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy code:', err);
      }
    };
  
    const uniqueContent = content;
    const lineCount = uniqueContent.split('\n').length;
    const showHeader = lineCount > 7;
  
    return (
      <div className={`modern-code-display ${!showHeader ? 'with-header' : ''}`}>
        <div className="code-header">
          <span className={`code-language ${!showHeader ? 'with-header' : ''}`}>{language}</span>
          <button onClick={handleCopy} className="copy-button sticky-copy-button">
            {copied ? <Tick01Icon size={16} /> : <Copy01Icon size={16} />}
          </button>
        </div>
  
        {theme === 'dark' ? (
          <div style={{ width: '100%', overflow: 'auto' }}>
            <SyntaxHighlighter
              language={language}
              wrapLines={true}
              wrapLongLines={true}
              style={customTheme}
              customStyle={{
                margin: 0,
                borderRadius: showHeader ? '0 0 8px 8px' : '8px',
                padding: '1rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                width: '100%',
                fontFamily: 'var(--font-mono)',
                fontSize: '0.8rem',
                lineHeight: '1.7',
                backgroundColor: 'var(--background-light)',
                border: '1px solid var(--border-color)',
                boxShadow: 'var(--shadow-elevated)',
              }}
            >
              {uniqueContent}
            </SyntaxHighlighter>
          </div>
        ) : (
          <div style={{ width: '100%', overflow: 'auto' }}>
            <SyntaxHighlighter
              language={language}
              wrapLines={true}
              wrapLongLines={true}
              customStyle={{
                margin: 0,
                borderRadius: showHeader ? '0 0 8px 8px' : '8px',
                padding: '1rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                width: '100%',
                fontFamily: 'var(--font-mono)',
                fontSize: '0.8rem',
                lineHeight: '1.3',
                backgroundColor: 'var(--background-light)',
                border: '1px solid var(--border-color)',
              }}
            >
              {uniqueContent}
            </SyntaxHighlighter>
          </div>
        )}
      </div>
    );
  };
  