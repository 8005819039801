import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Footer.css';
import CognoraLogo from '../../assets/Logo empty inverse (1).png';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { NewTwitterIcon } from 'hugeicons-react';

const Footer = ({ theme, toggleTheme }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section brand">
          <Link to="/">
            <img src={CognoraLogo} alt="Cognora Logo" className="footerlogo" />
          </Link>
          <p className="brand-description">
            Empowering education through AI-driven learning experiences.
          </p>
          <div className="social-links">
            <a href="https://twitter.com/CognoraAI" target="_blank" rel="noopener noreferrer">
              <NewTwitterIcon />
            </a>
            <a href="https://github.com/Cognora" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
            <a href="https://linkedin.com/company/cognoraai" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
          <button className="theme-toggle" onClick={toggleTheme}>
            {theme === 'light' ? '🌙 Dark Mode' : '☀️ Light Mode'}
          </button>
        </div>

        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            {/* <li><Link to="/careers">Careers</Link></li> */}
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="https://x.com/CognoraAI">X/Twitter</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Solutions</h4>
          <ul>
            <li><Link to="/studybuddy">StudyBuddy</Link></li>
            <span className="separator">More Coming Soon •</span>
            {/* <li><Link to="/api">Education API</Link></li> */}
            {/* <li><Link to="/pricing">Pricing</Link></li> */}
          </ul>
        </div>

        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            {/* <li><Link to="/documentation">Documentation</Link></li> */}
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>&copy; {new Date().getFullYear()} LEKAN INC. All rights reserved.</p>
          <div className="footer-bottom-links">
            <Link to="/privacy-policy">Privacy</Link>
            <span className="separator">•</span>
            <Link to="/terms-of-service">Terms</Link>
            <span className="separator">•</span>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;