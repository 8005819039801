import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {FaClock, FaUsers, FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import '../Styles/StudyBuddy.css';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import { Book, Upload, MessageSquare, FileText, Plus, Search, ChevronRight } from 'lucide-react';

const StudyBuddyLanding = () => {
  const [messageInput, setMessageInput] = useState('');

  const features = [
    {
      icon: <Book size={24} />,
      title: "Study Spaces",
      description: "Create dedicated spaces for each subject with organized materials and conversations"
    },
    {
      icon: <MessageSquare size={24} strokeWidth={2} />,
      title: "Intelligent Conversations",
      description: "Engage in context-aware discussions with memory of previous conversations"
    },
    {
      icon: <Upload size={24} />,
      title: "Resource Analysis",
      description: "Upload and analyze study materials with AI-powered comprehension"
    },
    {
      icon: <FileText size={24} />,
      title: "Learning Goals",
      description: "Set and track objectives for each study space"
    },
    {
      icon: <FaUsers />,
      title: "Personalized Learning",
      description: "Multiple learning modes adapted to your study style"
    },
    {
      icon: <FaClock size={24} />,
      title: "Multi-Model AI",
      description: "Adaptive AI system that selects the best model for your specific learning needs"
    },
    {
      icon: <FaClock style={{ fontSize: '24px' }} />,
      title: "Knowledge Tracking",
      description: "Monitor your study progress with built-in knowledge capacity tracking"
    },
    {
      icon: <FaClock size={24} />,
      title: "PDF Integration",
      description: "Seamless PDF analysis and integration with study conversations"
    }
  ];

  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true });

  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "StudyBuddy by Cognora",
    "applicationCategory": "EducationalApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "AI-powered learning companion that provides personalized tutoring."
  }).replace(/</g, '\\u003c');

  const handleSendMessage = () => {
    if (messageInput.trim()) {
      // Encode the message for URL safety
      const encodedMessage = encodeURIComponent(messageInput);
      
      // Construct the external URL with the message as a query parameter
      const externalUrl = `http://studdybuddy.ca/?message=${encodedMessage}&mode=tutor`;
      
      // Open in the same window
      window.location.href = externalUrl;
      
      // Alternatively, to open in a new tab:
      // window.open(externalUrl, '_blank');
    }
  };

  return (
    <>
      <Helmet>
        <title>StudyBuddy | Cognora</title>
        <meta property="og:title" content="StudyBuddy | Cognora" />
        <meta name="description" content="Transform your learning experience with StudyBuddy, an AI-powered tutor that adapts to your unique learning style. Available 24/7 for personalized education support." />
        <meta name="keywords" content="StudyBuddy, AI tutor, personalized learning, online tutoring, educational AI, study help" />
        <link rel="canonical" href="https://cognora.ca/studybuddy" />
        
        <script type="application/ld+json">
          {structuredData}
        </script>
      </Helmet>
      
      <div className="studybuddy-container">
        <section 
          ref={heroRef} 
          className={`studybuddy-hero ${heroInView ? 'animate-in' : ''}`}
        >
          <div className="hero-overlay"></div>
          <h1 className="gradient-text">Transform Your Learning Journey</h1>
          <p>Experience the future of education with AI-powered personalized tutoring</p>
          <div className="studybuddy-cta-buttons">
            <Link to="https://studdybuddy.ca/" className="studybuddy-btn studybuddy-btn-primary">
              Meet StudyBuddy
            </Link>
          </div>
        </section>

        <section 
          ref={featuresRef} 
          className={`feature-section ${featuresInView ? 'animate-in' : ''}`}
        >
          <h2 className="section-title">Why Choose StudyBuddy?</h2>
          <div className="feature-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section id="study-spaces-showcase" className="study-spaces-showcase">
          <div className="showcase-header">
            <h2>Organize Your Learning with Study Spaces</h2>
            <p>Create dedicated spaces for each subject, complete with AI-powered discussions, study materials, and generated content</p>
          </div>

          <div className="showcase-content">
                    <div className="showcase-visual">
                        <div className="feature-preview">
                            <div className="space-preview-header">
                                <div className="header-left">
                                    <div className="return-to-chats">
                                        <MessageSquare size={20} />
                                        <span>Chats</span>
                                    </div>
                                    <h3>Study Spaces</h3>
                                </div>
                                <div className="header-actions">
                                    <button className="create-space-btn">
                                        <Plus size={16} />
                                        New Space
                                    </button>
                                </div>
                            </div>

                            <div className="space-preview-search">
                                <Search size={18} className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search study spaces..."
                                    disabled
                                />
                            </div>

                            <div className="space-preview-grid">
                                {[
                                    {
                                        title: "Physics 101",
                                        description: "Classical mechanics and kinematics",
                                        index: 0
                                    },
                                    {
                                        title: "Data Structures",
                                        description: "Algorithms and programming concepts",
                                        index: 1
                                    },
                                    {
                                        title: "World History",
                                        description: "Modern historical analysis",
                                        index: 2
                                    }
                                ].map((space) => (
                                    <div 
                                        key={space.title}
                                        className="space-preview-card"
                                        style={{ '--index': space.index }}
                                    >
                                        <div className="space-header">
                                            <Book size={20} />
                                            <h3>{space.title}</h3>
                                        </div>
                                        <p className="space-description">{space.description}</p>
                                        <div className="space-footer">
                                            <div className="space-stats">
                                                <span className="stat">
                                                    <MessageSquare size={14} />
                                                    3 chats
                                                </span>
                                                <span className="stat">
                                                    <FileText size={14} />
                                                    2 files
                                                </span>
                                            </div>
                                            <ChevronRight size={16} className="arrow-icon" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


            <div className="spaces-feature-columns">
              <div className="spaces-feature-column">
                <div className="spaces-feature-card">
                  <div className="spaces-feature-icon">
                    <Book size={24} />
                  </div>
                  <div className="spaces-feature-content">
                    <h3>Organized Study Materials</h3>
                    <p>Upload and analyze PDFs, track learning goals, and keep all your study resources in one organized space.</p>
                  </div>
                </div>
                <div className="spaces-feature-card">
                  <div className="spaces-feature-icon">
                    <MessageSquare size={24} />
                  </div>
                  <div className="spaces-feature-content">
                    <h3>Focused Conversations</h3>
                    <p>Start multiple chat sessions within each space, maintaining context and history for deeper subject understanding.</p>
                  </div>
                </div>
              </div>

              <div className="spaces-feature-column">
                <div className="spaces-feature-card">
                  <div className="spaces-feature-icon">
                    <Upload size={24} />
                  </div>
                  <div className="spaces-feature-content">
                    <h3>PDF Integration</h3>
                    <p>Upload study materials and let our AI help you understand them through interactive discussions and analysis.</p>
                  </div>
                </div>
                <div className="spaces-feature-card">
                  <div className="spaces-feature-icon">
                    <FileText size={24} />
                  </div>
                  <div className="spaces-feature-content">
                    <h3>Learning Goals</h3>
                    <p>Set and track objectives for each study space, helping you stay focused and measure your progress.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ai-demo animate-in">
          <div className="demo-background-pattern"></div>
          <h2>Try StudyBuddy</h2>
          <div className="demo-container">
            <div className="input-area empty">
              <div className="input-wrapper empty">
                <textarea
                  className="chat-input scrollable empty"
                  placeholder="Ask a question or start a conversation..."
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <button 
                  className="send-button"
                  onClick={handleSendMessage}
                  disabled={!messageInput.trim()}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="success-path animate-in">
          <div className="path-connector"></div>
          <h2>Your Learning Journey</h2>
          <div className="path-steps">
            {[
              { step: 1, title: "Create Space", description: "Set up your study environment" },
              { step: 2, title: "Add Resources", description: "Upload study materials" },
              { step: 3, title: "Start Learning", description: "Engage in focused conversations" },
              { step: 4, title: "Track Progress", description: "Monitor your understanding" }
            ].map((step, index) => (
              <div key={index} className="path-step">
                <div className="step-number">{step.step}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="final-cta animate-in">
          <div className="floating-shapes"></div>
          <h2>Ready to Transform Your Learning?</h2>
          <p>Join students already chatting with StudyBuddy</p>
          <Link to="https://studdybuddy.ca/" className="cta-button cta-primary">
            Get Started Now <FaArrowRight />
          </Link>
        </section>

        <section className="trust-indicators">
          <div className="trust-grid">
            {[
              "Organized Study Spaces",
              "Smart PDF Analysis",
              "Multiple Learning Modes",
              "Progress Tracking"
            ].map((item, index) => (
              <div key={index} className="trust-item">
                <FaCheckCircle />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </section>

        
      </div>
    </>
  );
};

export default StudyBuddyLanding;