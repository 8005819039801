import React from 'react';
import '../Styles/CookiesPage.css';
import { Helmet } from 'react-helmet';

const CookiesPage = () => {
  return (
    <div className="cookies-container">
      <Helmet>
        <title>Cookie Policy | Cognora</title>
        <meta name="og:title" content="Cookie Policy | Cognora" />
        <meta name="description" content="Cognora's cookie-free approach to protecting your privacy while using our AI educational platform." />
        <link rel="canonical" href="https://cognora.ca/cookies" />
      </Helmet>
      <div className="cookies-content">
        <h1>🍪 No Cookies Here!</h1>
        <div className="cookie-message">
          <h2>Plot twist: We don't collect cookies!</h2>
          <p>In a world where everyone wants a bite of your data, we're keeping things simple and cookie-free.</p>
        </div>
        
        <div className="cookie-features">
          <div className="feature-box">
            <h3>Zero Tracking</h3>
            <p>We respect your privacy and don't track your movements across this website.</p>
          </div>
          <div className="feature-box">
            <h3>No Data Collection</h3>
            <p>What happens on your device, stays on your device.</p>
          </div>
          <div className="feature-box">
            <h3>Just Good Old Service</h3>
            <p>We're here to help you learn, not to collect your data.</p>
          </div>
        </div>

        <div className="cookie-footer">
          <p>PS: If you're hungry for actual cookies, try your local bakery! 🍪</p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPage; 