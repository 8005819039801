import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Styles/Blog.css';
import { blogPosts } from './Data/BlogPosts';

const Blog = () => {
  const [activeFilter, setActiveFilter] = useState('All');

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Cognora Blog",
    "description": "Insights on AI Innovation and Educational Technology",
    "publisher": {
      "@type": "Organization",
      "name": "Cognora",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cognora.ca/logo.png",
        "width": "112",
        "height": "112"
      }
    },
    "blogPost": blogPosts.map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "datePublished": post.date,
      "dateModified": post.lastModified,
      "author": {
        "@type": "Person",
        "name": post.author.name
      },
      "image": {
        "@type": "ImageObject",
        "url": post.imageUrl
      },
      "url": `https://cognora.ca/blog/${post.id}`,
      "description": post.excerpt,
      "keywords": post.tags.join(", ")
    }))
  };

  const getFilteredPosts = () => {
    if (activeFilter === 'All') {
      return blogPosts;
    }
    return blogPosts.filter(post => post.category === activeFilter);
  };

  return (
    <>
      <Helmet>
        <title>Cognora Blog </title>
        <meta name="description" content="Explore the latest insights on AI innovation, educational technology, and the future of learning from Cognora's team of experts." />
        <meta name="keywords" content="AI education blog, educational technology insights, artificial intelligence learning, StudyBuddy updates, AI tutoring news" />
        <link rel="canonical" href="https://cognora.ca/blog" />
        <link rel="alternate" type="application/rss+xml" title="Cognora Blog RSS Feed" href="https://cognora.ca/rss.xml" />
        <meta property="og:type" content="blog" />
        <meta property="og:title" content="Cognora Blog" />
        <meta property="og:description" content="Explore the latest insights on AI innovation, educational technology, and the future of learning from Cognora's team of experts." />
        <meta property="og:url" content="https://cognora.ca/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cognora Blog | AI Education Insights & Innovation" />
        <meta name="twitter:description" content="Explore the latest insights on AI innovation, educational technology, and the future of learning from Cognora's team of experts." />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="blog-container">
        <div className="blog-hero">
          <h1>Cognora Blog</h1>
          <p>Insights on AI, Education, and the Future of Learning</p>
        </div>

        <div className="blog-filters">
          <div className="categories">
            {['All', 'Product Release', 'Feature Release', 'Open Source Release', 'Research'].map(filter => (
              <span
                key={filter}
                className={activeFilter === filter ? 'active' : ''}
                onClick={() => setActiveFilter(filter)}
                role="button"
                tabIndex={0}
              >
                {filter}
              </span>
            ))}
          </div>
        </div>

        <div className="blog-grid">
          {getFilteredPosts()
            .sort((a, b) => b.id - a.id)
            .map(post => (
              <article key={post.id} className="blog-card">
                <div className="blog-card-image">
                  <img src={post.imageUrl} alt={post.title} loading="lazy" />
                </div>
                <div className="blog-card-content">
                  <span className="blog-category">{post.category}</span>
                  <h2>{post.title}</h2>
                  <p>{post.excerpt}</p>
                  <div className="blog-tags">
                    {post.tags.map(tag => (
                      <span key={tag} className="tag">{tag}</span>
                    ))}
                  </div>
                  <div className="blog-card-footer">
                    <div className="blog-meta">
                      <span className="blog-date">{post.displayDate}</span>
                    </div>
                    <Link to={`/blog/${post.id}`} className="read-more">
                      Read More →
                    </Link>
                  </div>
                </div>
              </article>
            ))}
        </div>
      </div>
    </>
  );
};

export default Blog;